import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import { type SelectOption } from '@/components/Select/Select'
import Table, { type Column } from '@/components/Table/Table'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useAuthStore from '@/store/useAuthStore'
import { getFilterValue } from '@/utils/get-filter-value'
import { getValue } from '@/utils/get-value'

import type { ClassesFiltersKey } from '../constants/classes-filters'
import { classesQueryOptions } from '../queries/classListFilters/classesQueryOptions'
import { studentsQueryOptions } from '../queries/classListFilters/studentsQueryOptions'
import { tutorsQueryOptions } from '../queries/classListFilters/tutorsQueryOptions'
import useAcademicLevelsOptions from '../queries/classListFilters/useAcademicLevelsOptions'
import useStudentClasses, { type Class } from '../queries/useStudentClasses'

const routeApi = getRouteApi('/_auth/students-and-classes/classes/')

const ClassesView = () => {
  const { t } = useTranslation(['classes'])
  const { page, pageSize, id, academicLevelId, tutorId, studentId } =
    routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const { user } = useAuthStore()

  const { data: classes, isLoading: isClassesLoading } = useStudentClasses({
    id: id?.map(getValue),
    academicLevelId,
    tutorId: tutorId?.map(getValue),
    studentId: studentId?.map(getValue),
    page,
    pageSize
  })

  const tableColumns: Column<Class>[] = [
    {
      key: 'name',
      width: 150,
      title: t('header.class'),
      cellDataTestId: 'class',
      render: (value: Class) => (
        <LinkText
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    {
      dataIndex: ['academicLevel', 'name'],
      key: 'academicLevel',
      cellDataTestId: 'academic-level',
      title: t('header.academic-level'),
      width: 120
    },

    {
      dataIndex: ['tutor', 'fullName'],
      key: 'tutor',
      cellDataTestId: 'tutor',
      title: t('header.tutor'),
      width: 200
    },
    {
      dataIndex: 'numberOfStudents',
      key: 'numberOfStudents',
      cellDataTestId: 'number-of-students',
      title: t('header.number-of-students'),
      width: 150
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const { data: academicLevels, isLoading: isAcademicLevelsLoading } =
    useAcademicLevelsOptions()

  const changeFilter = (
    key: ClassesFiltersKey,
    value?: string | string[] | SelectOption<string>[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: { page: 1, pageSize: DEFAULT_PAGE_SIZE }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.class'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        queryOptions: classesQueryOptions,
        value: id,
        placeholder: t('label.class'),
        onChange: value => changeFilter('id', value)
      }
    },
    {
      label: t('label.academic-level'),
      variant: 'multiselect',
      filterProps: {
        id: 'academic-level',
        dataTestId: 'academic-level-filter',
        multiple: true,
        loading: isAcademicLevelsLoading,
        options: academicLevels || [],
        value: academicLevelId,
        placeholder: t('label.academic-level'),
        onChange: value => changeFilter('academicLevelId', value)
      }
    },
    {
      label: t('label.tutor'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'tutorId',
        dataTestId: 'tutor-filter',
        multiple: true,
        queryOptions: tutorsQueryOptions,
        value: tutorId,
        placeholder: t('label.tutor'),
        onChange: value => changeFilter('tutorId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        queryOptions: studentsQueryOptions,
        value: studentId,
        placeholder: t('label.student'),
        onChange: value => changeFilter('studentId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(filter => filter.filterProps.value)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          user?.isSuperAdmin ? (
            <Button
              asLink
              to="/students-and-classes/classes/add"
              icon={<AddIcon />}
            >
              {t('button.add-new-class')}
            </Button>
          ) : null
        }
      />
      <Table
        isLoading={isClassesLoading}
        id="classes-table"
        data={classes?.list || []}
        columns={tableColumns}
        pagination={{
          count: classes?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
      />
    </>
  )
}

export default ClassesView
