import clsx from 'clsx'
import { format, isMatch, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ErrorCircleFilledIcon from '@/assets/icons/error-circle-filled.svg?react'
import { onFocusOutside } from '@/utils/on-focus-outside'

import TimePickerDropdown from './TimePickerDropdown'
import styles from './TimeRangePicker.module.scss'
import type { FormFieldType } from '../../types/form-field-type'
import type { Time } from '../../types/time'
import { time } from '../../utils/zod'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

type TimeRangePickerProps = FormFieldType<{ from?: Time; to?: Time }>

const TimeRangePicker = (props: TimeRangePickerProps) => {
  const { t } = useTranslation('common')
  return (
    <div
      className={clsx(styles.container, props.invalid && styles.error)}
      onBlur={onFocusOutside(() => props.onBlur?.())}
    >
      <TimeRangeInput
        value={props.value?.from}
        onChange={from => props.onChange?.({ from, to: props.value?.to })}
        placeholder={t('placeholder.start-hour')}
      />
      <span className={styles.separator}>-</span>
      <TimeRangeInput
        value={props.value?.to}
        onChange={to => props.onChange?.({ from: props.value?.from, to })}
        placeholder={t('placeholder.end-hour')}
        startFrom={props.value?.from}
      />
      {props.value?.from || props.value?.to ? (
        <ButtonIcon
          onClick={() => {
            props.onChange?.({ from: undefined, to: undefined })
          }}
          variant="tertiary"
          size="extra-small"
          ariaLabel={t('label.clear-icon')}
          className={styles.clearIcon}
        >
          <ErrorCircleFilledIcon />
        </ButtonIcon>
      ) : null}
    </div>
  )
}

type TimeRangeInputProps = {
  value: Time | undefined
  onChange: (value: Time | undefined) => void
  placeholder: string
  startFrom?: Time
}

const TimeRangeInput = (props: TimeRangeInputProps) => {
  const [value, setValue] = useState(props.value ?? '')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  // sync input value on props change
  useEffect(() => {
    setValue(props.value ?? '')
  }, [props.value])

  const handleBlur = () => {
    // format 12:1 as 12:01 etc and emit change
    if (isMatch(value, 'HH:mm')) {
      const formatted = time().parse(
        format(parse(value, 'HH:mm', new Date()), 'HH:mm')
      )
      setValue(formatted)
      props.onChange(formatted)
    }
    // clear on blur if value is invalid
    else {
      setValue('')
      props.onChange(undefined)
    }
  }

  return (
    <div
      className={styles.inputWrapper}
      onBlur={onFocusOutside(() => setIsDropdownOpen(false))}
    >
      <input
        value={value}
        onChange={event => setValue(event.target.value)}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        className={styles.input}
        onFocus={() => setIsDropdownOpen(true)}
      />
      <TimePickerDropdown
        open={isDropdownOpen}
        onOk={() => setIsDropdownOpen(false)}
        value={time().optional().catch(undefined).parse(value)}
        onChange={dropdownValue => {
          setValue(dropdownValue)
          props.onChange(dropdownValue)
        }}
        className={styles.dropdown}
        startFrom={props.startFrom}
      />
    </div>
  )
}

export default TimeRangePicker
