import { useQuery } from '@tanstack/react-query'
import { isAfter, isBefore } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'

import { findById } from '../utils/find-by-id'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1),
    start_date: z.coerce.date(),
    end_date: z.coerce.date()
  })
)

export type Semester = {
  id: string
  name: string
  startDate: Date
  endDate: Date
}

const parseResponse = (response: z.infer<typeof responseSchema>): Semester[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    startDate: item.start_date,
    endDate: item.end_date
  }))

export const semesterQueryOptions = {
  queryKey: ['panelPeriodsSemestersList'],
  staleTime: 60 * 1000,
  queryFn: async () => {
    const semestersResponse = await httpService.panel.panelPeriodsSemestersList(
      {
        page_size: 'max',
        fetchKeys: {
          id: true,
          name: true,
          start_date: true,
          end_date: true
        }
      }
    )

    const semesters = parseResponse(
      responseSchema.parse(semestersResponse.results)
    )

    const currentSemester = semesters?.find(
      semester =>
        !isBefore(new Date(), semester.startDate) &&
        !isAfter(new Date(), semester.endDate)
    )

    const options = semesters?.map(semester => ({
      label: semester.name,
      value: semester.id
    }))

    return {
      list: semesters,
      options,
      currentSemester: currentSemester || semesters[semesters.length - 1],
      getSemester: findById(semesters)
    }
  }
}

const useSemesters = () => useQuery(semesterQueryOptions)

export default useSemesters
