import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import type { SelectOption } from '@/components/Select/Select'
import Table, { type Column } from '@/components/Table/Table'
import CourseRowDetails from '@/components/common/CourseRowDetails'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradebookButton from '@/components/common/GradebookButton'
import TeachersColumn from '@/components/common/TeachersColumn/TeachersColumn'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { studentsQueryOptions } from '@/queries/studentsQueryOptions'
import useCourseGroup from '@/queries/useCourseGroup'
import useSemesters from '@/queries/useSemesters'
import { getFilterValue } from '@/utils/get-filter-value'
import { getValue } from '@/utils/get-value'

import type { CoursesAndGroupsFiltersKey } from '../constants/courses-and-groups-filters'
import { coursesQueryOptions } from '../queries/classCoursesFilters/coursesQueryOptions'
import { groupsQueryOptions } from '../queries/classCoursesFilters/groupQueryOptions'
import { teachersQueryOptions } from '../queries/classCoursesFilters/teacherQueryOptions'
import useClassGroups, { type Group } from '../queries/useClassGroups'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/courses-and-groups'
)

const ClassCoursesAndGroups = () => {
  const { t } = useTranslation(['classes'])
  const { classId } = routeApi.useParams()
  const {
    page,
    pageSize,
    course,
    group,
    student,
    teacherOrCoTeacher,
    semester
  } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const optionsParams = {
    classId,
    semester
  }

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const { data: groups, isLoading } = useClassGroups({
    classId,
    page,
    pageSize,
    courseId: course?.map(getValue),
    groupId: group?.map(getValue),
    studentId: student?.map(getValue),
    teacherOrCoTeacherId: teacherOrCoTeacher?.map(getValue),
    semester
  })

  const columns: Column<Group>[] = [
    {
      title: t('header.course'),
      key: 'course',
      dataIndex: ['course', 'name'],
      cellDataTestId: 'course',
      width: 215
    },
    {
      title: t('header.group'),
      key: 'group',
      cellDataTestId: 'group',
      dataIndex: 'name',
      width: 215
    },
    {
      title: t('header.teachers'),
      key: 'teacher',
      cellDataTestId: 'teacher',
      render: (value: Group) => (
        <TeachersColumn teacher={value.teacher} coTeacher={value.coTeacher} />
      ),
      width: 215
    },
    {
      key: 'gradebook',
      render: (value: Group) => (
        <GradebookButton
          courseId={value.course.id}
          groupId={value.id}
          semesterId={semester}
        />
      ),
      fixed: 'right',
      width: 40,
      filled: true
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const changeFilter = (
    key: CoursesAndGroupsFiltersKey,
    value?: string | string[] | SelectOption<string>[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semester,
        placeholder: t('label.semester'),
        onChange: value => {
          navigate({
            search: {
              semester: value,
              page: 1,
              pageSize: DEFAULT_PAGE_SIZE
            }
          })
        }
      }
    },
    {
      label: t('label.student'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        queryOptions: studentsQueryOptions,
        value: student,
        placeholder: t('label.student'),
        onChange: value => changeFilter('student', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        multiple: true,
        queryOptions: search =>
          coursesQueryOptions({ ...optionsParams, search }),
        value: course,
        placeholder: t('label.course'),
        onChange: value => changeFilter('course', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        multiple: true,
        queryOptions: search =>
          groupsQueryOptions({ ...optionsParams, search }),
        value: group,
        placeholder: t('label.group'),
        onChange: value => changeFilter('group', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'teacher',
        dataTestId: 'teacher-filter',
        multiple: true,
        queryOptions: search =>
          teachersQueryOptions({ ...optionsParams, search }),
        value: teacherOrCoTeacher,
        placeholder: t('label.teacher'),
        onChange: value => changeFilter('teacherOrCoTeacher', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="class-groups"
        data={groups?.list || []}
        columns={columns}
        isLoading={isLoading}
        pagination={{
          count: groups?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <CourseExpandendRow groupId={record.id} semesterId={semester} />
        )}
      />
    </>
  )
}

const CourseExpandendRow = (props: {
  groupId: string
  semesterId?: string
}) => {
  const { data: group, isLoading } = useCourseGroup(
    props.groupId,
    props.semesterId
  )

  return (
    <CourseRowDetails isLoading={isLoading} students={group?.students || []} />
  )
}
export default ClassCoursesAndGroups
