import { useTranslation } from 'react-i18next'

import GradebookIcon from '@/assets/icons/gradebook.svg?react'

import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Tooltip from '../Tooltip/Tooltip'

type GradebookButtonProps = {
  courseId: string
  groupId: string
  semesterId?: string
}
const GradebookButton = (props: GradebookButtonProps) => {
  const { t } = useTranslation('common')
  return (
    <Tooltip
      text={t('help.go-to-gradebook')}
      trigger={
        <ButtonIcon
          size="medium"
          variant="tertiary"
          asLink
          to="/timetable/$courseId/$groupId/grades"
          params={{
            courseId: props.courseId,
            groupId: props.groupId
          }}
          search={{
            semesterId: props.semesterId
          }}
        >
          <GradebookIcon />
        </ButtonIcon>
      }
    />
  )
}
export default GradebookButton
