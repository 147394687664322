import { isBefore, isEqual } from 'date-fns'
import { z } from 'zod'

import type { FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import { timeRange } from '@/utils/zod'

import { isDayOfWeek } from './days-of-week'

const availabilitySlotSchema = z.object({
  isOptional: z.boolean(),
  time: timeRange(
    i18n.t('help.fill-empty-slots', {
      ns: 'availabilityManagement'
    })
  )
})

const availabilitySlots = z
  .array(availabilitySlotSchema)
  .superRefine((slots, ctx) => {
    for (let index1 = 0; index1 < slots.length; index1++) {
      const slot1 = slots[index1]
      if (!slot1.time.from || !slot1.time.to) continue
      for (let index2 = 0; index2 < slots.length; index2++) {
        if (index1 === index2) continue
        const slot2 = slots[index2]
        if (!slot2.time.from || !slot2.time.to) continue
        if (
          isBefore(slot1.time.from, slot2.time.to) ||
          isEqual(slot1.time.from, slot2.time.to) ||
          isBefore(slot2.time.from, slot1.time.to) ||
          isEqual(slot2.time.from, slot1.time.to)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('help.overlapping-slot', {
              ns: 'availabilityManagement'
            }),
            path: [index1, 'timeRange']
          })
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('help.overlapping-slot', {
              ns: 'availabilityManagement'
            }),
            path: [index2, 'timeRange']
          })
        }
      }
    }
  })

export const setAvailabilityFormSchema = z.object({
  monday: availabilitySlots,
  tuesday: availabilitySlots,
  wednesday: availabilitySlots,
  thursday: availabilitySlots,
  friday: availabilitySlots,
  saturday: availabilitySlots,
  sunday: availabilitySlots
})

export type AvailabilitySlotForm = z.infer<typeof availabilitySlotSchema>
export type SetAvailabilityForm = z.infer<typeof setAvailabilityFormSchema>

export type SetAvailabilityPayload = SetAvailabilityForm & {
  userId: string
  semesterId: string
}

export const setAvailabilityErrorsMapper: FieldErrorsMapper<
  SetAvailabilityForm
> = attr => {
  if (isDayOfWeek(attr)) return attr
  const [fieldName, index] = attr.split('.')
  if (!isDayOfWeek(fieldName)) return 'root'
  return `${fieldName}.${Number(index)}.time`
}
