import { getRouteApi } from '@tanstack/react-router'
import { takeRight } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from 'usehooks-ts'

import AddIcon from '@/assets/icons/add.svg?react'
import CancelIcon from '@/assets/icons/cancel.svg?react'
import SendLinkIcon from '@/assets/icons/send-link.svg?react'
import SendMessageIcon from '@/assets/icons/send-message.svg?react'
import UnlockIcon from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import type { SelectOption } from '@/components/Select/Select'
import Table, { type TableAction, type Column } from '@/components/Table/Table'
import TableTopBarActions from '@/components/TableTopBarActions/TableTopBarActions'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import SendAvailabilityRequestModal from '@/components/common/SendAvailabilityRequestModal/SendAvailabilityRequestModal'
import TruncateElementsList from '@/components/common/TruncateElementsList'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { userProfiles } from '@/constants/user-profiles'
import useRowSelection from '@/hooks/useRowSelection'
import { toast } from '@/hooks/useToast'
import useSendBulkAvailabilityRequest from '@/mutations/useSendBulkAvailabilityRequest'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import { type UserProfile } from '@/types/user-profile'
import type { UserStatus } from '@/types/user-status'
import { getFeatureFlag } from '@/utils/get-feature-flag'
import { getFilterValue } from '@/utils/get-filter-value'
import { getUserStatusTooltip } from '@/utils/get-user-status-tooltip'
import { getValue } from '@/utils/get-value'
import { mergeOptions } from '@/utils/merge-options'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './UsersListView.module.scss'
import ConfirmModal from '../../../components/ConfirmModal'
import type { UsersFiltersKeys } from '../constants/users-filters'
import useActivateUser from '../mutations/useActivateUser'
import useBlockUser from '../mutations/useBlockUser'
import useSendActivationUserLink, {
  type ActivateLinkResponse
} from '../mutations/useSendUserActivationLink'
import useAllUsersCount from '../queries/useAllUsersCount'
import useUsers, { type User } from '../queries/useUsers'
import { useUsersFilters } from '../queries/useUsersFilters'
import useUsersIds from '../queries/useUsersIds'
import { getColorByProfile, getLabelByProfile } from '../utils/getProfileTag'

const routeApi = getRouteApi('/_auth/users/')

const ENABLED_AVAILABILITY_MANAGEMENT = getFeatureFlag(
  'availability-management'
)

const useUserListFilters = () => {
  const [nameSearch, setNameSearch] = useDebounceValue('', 200)
  const [emailSearch, setEmailSearch] = useDebounceValue('', 200)
  const [phoneSearch, setPhoneSearch] = useDebounceValue('', 200)

  const { nameQuery, emailQuery, phoneQuery, statusQuery } = useUsersFilters({
    name: { search: nameSearch },
    email: { search: emailSearch },
    phone: { search: phoneSearch }
  })

  return {
    name: {
      setSearch: setNameSearch,
      query: nameQuery
    },
    email: {
      setSearch: setEmailSearch,
      query: emailQuery
    },
    phone: {
      setSearch: setPhoneSearch,
      query: phoneQuery
    },
    status: {
      query: statusQuery
    }
  }
}

const UsersListView = () => {
  const { t } = useTranslation('users')
  const { id, page, pageSize, status, phoneNumber, email, profile } =
    routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const selectedIds = id?.map(getValue) || []
  const emails = email?.map(getValue) || []
  const phoneNumbers = phoneNumber?.map(getValue) || []

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch
  } = useUsers({
    id: selectedIds,
    page,
    pageSize,
    status,
    phoneNumber: phoneNumbers,
    email: emails,
    profile
  })

  const { data: allUsersIds, isLoading: isAllUsersLoading } = useUsersIds({
    id: selectedIds,
    status,
    phoneNumber: phoneNumbers,
    email: emails,
    profile
  })

  const {
    name: { query: nameQuery, setSearch: setNameSearch },
    email: { query: emailQuery, setSearch: setEmailSearch },
    phone: { query: phoneQuery, setSearch: setPhoneSearch },
    status: { query: statusQuery }
  } = useUserListFilters()

  const { data: allUsersCount } = useAllUsersCount()
  const { data: semesters } = useSemesters()

  const selection = useRowSelection({
    count: allUsersIds?.count || 0,
    itemsToSelect: allUsersIds?.list || []
  })

  const { user } = useAuthStore()

  const handleBlock = () => {
    if (!!currentUser) blockUser({ id: currentUser?.id })
  }

  const [openBlockConfirmModal, setOpenBlockConfirmModal] = useState(false)
  const [openActivateConfirmModal, setOpenActivateConfirmModal] =
    useState(false)

  const [openActivationLinkModal, setOpenActivationLinkModal] = useState(false)
  const [openSendAvailabilityModal, setOpenSendAvailabilityModal] =
    useState(false)
  const [currentUser, setCurrentUser] = useState<User | null>()

  const handleActivate = () => {
    if (!!currentUser) activateUser({ id: currentUser?.id })
  }

  const closeBlockConfirmModal = () => {
    refetch()
    setOpenBlockConfirmModal(false)
    setCurrentUser(null)
  }

  const { mutate: blockUser, isPending: isBlockUserLoading } = useBlockUser({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.user-blocked', { NAME: currentUser?.name })
      })
      closeBlockConfirmModal()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.user-blocked-failed', {
          NAME: currentUser?.name
        })
      })
      closeBlockConfirmModal()
    }
  })

  const closeActivateConfirmModal = () => {
    refetch()
    setOpenActivateConfirmModal(false)
    setCurrentUser(null)
  }

  const { mutate: activateUser, isPending: isActivateUserLoading } =
    useActivateUser({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.user-activate', {
            NAME: currentUser?.name
          })
        })
        closeActivateConfirmModal()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.user-activate', {
            NAME: currentUser?.name
          })
        })
        closeActivateConfirmModal()
      }
    })

  const { isPending, mutate: sendActivationLink } = useSendActivationUserLink({
    onSuccess: (data?: ActivateLinkResponse) => {
      if (data?.successfullySent) {
        if (currentUser?.id) {
          toast({
            variant: 'success',
            title: t('toast.successfully-send-activation-link')
          })
        } else {
          toast({
            variant: 'success',
            title: t('toast.successfully-send-activation-links', {
              NUMBER: data?.successfullySent
            })
          })
        }
      }
      if (data?.unsuccessfullySent) {
        toast({
          variant: 'error',
          title: t('toast.failed-send-activation-links', {
            NUMBER: data.unsuccessfullySent
          })
        })
      }
      refetch()
      setOpenActivationLinkModal(false)
      setCurrentUser(null)
      selection.clearSelection()
    }
  })

  const handleSendActivationLink = () => {
    const ids = currentUser?.id ? [currentUser?.id] : selection.selectedItems

    sendActivationLink({
      ids
    })
  }

  const {
    mutate: sendAvailabilityRequest,
    isPending: isSendBulkAvailabilityRequestPending
  } = useSendBulkAvailabilityRequest({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('notification.successfully-sent-email')
      })
      setOpenSendAvailabilityModal(false)
      selection.clearSelection()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('notification.failed-to-sent-email')
      })
    }
  })

  const handleSendBulkAvailabilityRequest = (semesterId: string) => {
    sendAvailabilityRequest({
      semesterId,
      userIds: selection.selectedItems
    })
  }

  const actions: (row: User) => TableAction<User>[] = row =>
    user?.isSuperAdmin
      ? [
          {
            icon: <SendLinkIcon />,
            text:
              row.status === 'inactive'
                ? t('button.send-activation-link')
                : t('button.resend-activation-link'),
            hidden: row.status === 'blocked',
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenActivationLinkModal(true)
            }
          },
          {
            icon: <CancelIcon />,
            text: t('button.block-user'),
            hidden:
              row.status === 'blocked' ||
              row.status === 'inactive' ||
              row.id === user.id,
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenBlockConfirmModal(true)
            }
          },
          {
            icon: <UnlockIcon />,
            text: t('button.activate-user'),
            hidden:
              row.status === 'active' ||
              row.status === 'inactive' ||
              row.id === user.id,
            onClick: (item: User) => {
              setCurrentUser(item)
              setOpenActivateConfirmModal(true)
            }
          }
        ].filter(item => !item.hidden)
      : []

  const handleChangeFilter = (
    key: UsersFiltersKeys,
    value: SelectOption<string>[] | string[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const filterItems: Filter[] = [
    {
      variant: 'multiselect',
      label: t('label.name'),
      filterProps: {
        id: 'name-filter',
        options: mergeOptions(
          nameQuery.data?.pages.flatMap(({ results }) => results),
          id
        ),
        loading: nameQuery.isLoading,
        multiple: true,
        value: id,
        mode: 'detailed',
        placeholder: t('label.name'),
        onChange: value => handleChangeFilter('id', value),
        onInputChange: setNameSearch,
        onMenuScrollToBottom: () => nameQuery.fetchNextPage()
      }
    },
    {
      variant: 'multiselect',
      label: t('label.role'),
      filterProps: {
        id: 'role-filter',
        options: userProfiles,
        multiple: true,
        value: profile,
        placeholder: t('label.role'),
        onChange: value => handleChangeFilter('profile', value)
      }
    },
    {
      variant: 'multiselect',
      label: t('label.email'),
      filterProps: {
        id: 'email-filter',
        options: mergeOptions(
          emailQuery.data?.pages.flatMap(({ results }) => results),
          email
        ),
        loading: emailQuery.isLoading,
        multiple: true,
        mode: 'detailed',
        value: email,
        placeholder: t('label.email'),
        onChange: value => handleChangeFilter('email', value),
        onInputChange: setEmailSearch,
        onMenuScrollToBottom: () => emailQuery.fetchNextPage()
      }
    },
    {
      variant: 'multiselect',
      label: t('label.phone-number'),
      filterProps: {
        id: 'phone-number-filter',
        options: mergeOptions(
          phoneQuery.data?.pages.flatMap(({ results }) => results),
          phoneNumber
        ),
        loading: phoneQuery.isLoading,
        multiple: true,
        mode: 'detailed',
        value: phoneNumber,
        placeholder: t('label.phone-number'),
        onChange: value => handleChangeFilter('phoneNumber', value),
        onInputChange: setPhoneSearch,
        onMenuScrollToBottom: () => phoneQuery.fetchNextPage()
      }
    },
    {
      variant: 'multiselect',
      label: t('label.status'),
      filterProps: {
        id: 'status-filter',
        options: statusQuery.data || [],
        loading: statusQuery.isLoading,
        multiple: true,
        value: status,
        placeholder: t('label.status'),
        onChange: value => handleChangeFilter('status', value)
      }
    }
  ]

  const tableColumns: Column<User>[] = [
    {
      key: 'name',
      title: t('header.name'),
      width: 200,
      render: (value: User) => (
        <LinkText
          to="/users/$userId/details"
          params={{
            userId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    {
      dataIndex: 'profiles',
      key: 'profiles',
      title: t('header.role'),
      width: 200,
      render: value => <UserProfileColumn profiles={value} />
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: t('header.email'),
      width: 200
    },
    {
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
      title: t('header.phone-number')
    },
    {
      dataIndex: 'status',
      key: 'status',
      cellDataTestId: 'status',
      title: t('header.status'),
      width: 160,
      render: (value: UserStatus) => (
        <Tooltip
          trigger={<Tag {...userStatusTags[value]} />}
          text={getUserStatusTooltip(value)}
        />
      )
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const isSomeFilterSelected = filterItems.some(
    filter => filter.filterProps.value
  )

  const bulkActions = [
    ...(ENABLED_AVAILABILITY_MANAGEMENT
      ? [
          {
            text: t('button.send-availability-request'),
            icon: <SendMessageIcon />,
            onClick: () => setOpenSendAvailabilityModal(true)
          }
        ]
      : []),
    {
      text: t('button.send-activation-link'),
      icon: <SendLinkIcon />,
      onClick: () => setOpenActivationLinkModal(true)
    }
  ]

  return (
    <BasicLayout
      header={t('header.teachers-and-users')}
      moduleName={t('header.administration', { ns: 'common' })}
    >
      <Filters
        filters={filterItems}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          user?.isSuperAdmin ? (
            <Button asLink to="/users/add" icon={<AddIcon />}>
              {t('button.add-new-user')}
            </Button>
          ) : null
        }
      />
      <Table
        id="users-table"
        isLoading={isUsersLoading || isAllUsersLoading}
        data={users?.list || []}
        actions={actions}
        columns={tableColumns}
        pagination={{
          count: users?.count,
          pageSize,
          page
        }}
        rowSelection={
          user?.isSuperAdmin
            ? {
                selectedItems: selection.selectedItems,
                allCheckboxValue: selection.getSelectAllCheckboxValue(),
                count: users?.count || 0,
                labelHeader: t('label.select-all-users'),
                labelItem: (row: User) =>
                  t('label.select-user', { USER: row.name }),
                onSelect: selection.onSelect,
                onSelectAll: selection.onSelectAll
              }
            : undefined
        }
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        topBar={
          user?.isSuperAdmin ? (
            <TableTopBarActions
              actions={bulkActions}
              count={allUsersCount || 0}
              selectedItemsLength={selection.selectedItems.length}
            />
          ) : null
        }
      />

      <ConfirmModal
        id="activation-link-modal"
        header={t('header.send-activation-link')}
        subheader={
          currentUser?.id
            ? t('help.send-activation-link-single', {
                NAME: currentUser?.name
              })
            : t('help.send-activation-link-bulk', {
                NUMBER: selection.selectedItems.length
              })
        }
        confirmButton={
          <Button
            variant="primary"
            onClick={handleSendActivationLink}
            loading={isPending}
          >
            {t('button.send')}
          </Button>
        }
        open={openActivationLinkModal}
        onOpenChange={setOpenActivationLinkModal}
      />

      <ConfirmModal
        id="blocked-modal"
        header={t('header.block-user')}
        subheader={t('help.want-to-block-user', {
          NAME: currentUser?.name
        })}
        confirmButton={
          <Button
            variant="danger"
            onClick={handleBlock}
            loading={isBlockUserLoading}
          >
            {t('button.block')}
          </Button>
        }
        open={openBlockConfirmModal}
        onOpenChange={setOpenBlockConfirmModal}
      />

      <ConfirmModal
        id="activate-modal"
        header={t('header.activate-user')}
        subheader={t('help.want-to-activate-user', {
          NAME: currentUser?.name
        })}
        confirmButton={
          <Button onClick={handleActivate} loading={isActivateUserLoading}>
            {t('button.activate')}
          </Button>
        }
        open={openActivateConfirmModal}
        onOpenChange={setOpenActivateConfirmModal}
      />
      <SendAvailabilityRequestModal
        open={openSendAvailabilityModal}
        onOpenChange={setOpenSendAvailabilityModal}
        selectedSemester={semesters?.currentSemester.id || ''}
        description={t('help.send-availability-request-email', {
          count: selection.selectedItems.length
        })}
        onSendRequest={handleSendBulkAvailabilityRequest}
        sendRequestLoading={isSendBulkAvailabilityRequestPending}
      />
    </BasicLayout>
  )
}

const UserProfileColumn = ({ profiles }: { profiles: UserProfile[] }) => {
  const [isExpandedProfiles, setIsExpandedProfiles] = useState(false)

  return (
    <TruncateElementsList
      expanded={isExpandedProfiles}
      onExpandedChange={setIsExpandedProfiles}
      elipsis={hiddenItemsCount => (
        <Tooltip
          text={takeRight(profiles, hiddenItemsCount).join(', ')}
          trigger={
            <Tag
              color="white"
              variant="with-background"
              label={`+${hiddenItemsCount}`}
              onClick={() => {
                setIsExpandedProfiles(true)
              }}
            />
          }
        />
      )}
    >
      {profiles.map((userProfile, index) => (
        <Tag
          variant="with-background"
          className={styles.profileTag}
          key={index}
          label={getLabelByProfile(userProfile)}
          color={getColorByProfile(userProfile)}
        />
      ))}
    </TruncateElementsList>
  )
}

export default UsersListView
