import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'
import { requiredString, userStatus } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: requiredString(),
  email: requiredString(),
  phone_number: z.string().nullable(),
  status: userStatus,
  students: z.array(
    z.object({
      id: requiredString(),
      name: requiredString(),
      status: userStatus,
      email: requiredString(),
      academic_level: z
        .object({
          id: requiredString(),
          name: requiredString()
        })
        .nullable(),
      phone_number: z.string().nullable(),
      student_class: z
        .object({
          id: requiredString(),
          name: requiredString()
        })
        .nullable()
    })
  )
})

export type Student = {
  id: string
  fullName: string
  status: UserStatus
  email: string
  phoneNumber?: string
  academicLevel?: {
    id: string
    name: string
  }
  studentClass?: {
    id: string
    name: string
  }
}

export type Parent = {
  id: string
  fullName: string
  email: string
  phoneNumber?: string
  status: UserStatus
  students: Student[]
  firstName: string
  lastName: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Parent => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name,
  fullName: response.full_name,
  email: response.email,
  phoneNumber: response.phone_number || undefined,
  status: response.status,
  students: response.students.map(student => ({
    id: student.id,
    fullName: student.name,
    status: student.status,
    email: student.email,
    academicLevel: student.academic_level || undefined,
    phoneNumber: student.phone_number || undefined,
    studentClass: student.student_class || undefined
  }))
})

const useParent = (id: string) =>
  useQuery({
    queryKey: ['panelParentsRetrieve', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      const parentResponse = await httpService.panel.panelParentsRetrieve(id, {
        fetchKeys: {
          id: true,
          first_name: true,
          last_name: true,
          full_name: true,
          email: true,
          phone_number: true,
          status: true,
          students: true
        }
      })
      return parseResponse(responseSchema.parse(parentResponse))
    }
  })

export default useParent
