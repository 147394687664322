import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import {
  useController,
  useFieldArray,
  useForm,
  type UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import MoonIcon from '@/assets/icons/moon.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Checkbox from '@/components/Checkbox/Checkbox'
import FormField from '@/components/FormField'
import Label from '@/components/Label'
import ModalForm from '@/components/Modal/ModalForm'
import Switch from '@/components/Switch/Switch'
import TimeRangePicker from '@/components/TimePicker/TimeRangePicker'
import { AsyncSelect } from '@/components/common/AsyncSelect'
import DirtyModal from '@/components/common/DirtyModal'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import { useUserAvailability, type AvailabilitySlot } from '@/modules/users'
import type { Semester } from '@/queries/useSemesters'
import { usersQueryOptions } from '@/queries/usersQueryOptions'
import type { LabelAndValue } from '@/types/label-and-value'

import styles from './AvailabilityModal.module.scss'
import { daysOfWeek, type DayOfWeek } from '../../constants/days-of-week'
import {
  setAvailabilityFormSchema,
  type AvailabilitySlotForm,
  type SetAvailabilityForm
} from '../../constants/set-availability-payload'
import useSetAvailability from '../../mutations/useSetAvailability'

type AvailabilityModalProps = {
  title?: string
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onSuccess?: () => void
  semester: Semester
  userId?: string
}

const defaultValues = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
}

export const AvailabilityModal = (props: AvailabilityModalProps) => {
  const form = useForm<SetAvailabilityForm>({
    resolver: zodResolver(setAvailabilityFormSchema),
    defaultValues: {
      ...defaultValues
    },
    mode: 'onBlur'
  })

  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState(false)
  const isDirty = form.formState.isDirty

  const [user, setUser] = useState<LabelAndValue>()
  const userId = props.userId ? props.userId : user?.value

  const userAvailability = useUserAvailability({
    semester: props.semester.id,
    userId
  })

  useEffect(() => {
    if (!userId) form.reset({ ...defaultValues })
    else if (userAvailability.data)
      form.reset({
        monday: parseAvailabilitySlots(userAvailability.data.monday),
        tuesday: parseAvailabilitySlots(userAvailability.data.tuesday),
        wednesday: parseAvailabilitySlots(userAvailability.data.wednesday),
        thursday: parseAvailabilitySlots(userAvailability.data.thursday),
        friday: parseAvailabilitySlots(userAvailability.data.friday),
        saturday: parseAvailabilitySlots(userAvailability.data.saturday),
        sunday: parseAvailabilitySlots(userAvailability.data.sunday)
      })
  }, [userAvailability.data, form, userId, props.open])

  useEffect(() => {
    if (props.open) setUser(undefined)
  }, [form, props.open, setUser])

  const { t } = useTranslation('availabilityManagement')

  const {
    mutate: setAvailability,
    formErrors,
    isPending
  } = useSetAvailability({
    onSuccess: () => {
      toast({
        title: t('toast.successfully-saved-changes'),
        variant: 'success'
      })
      props.onOpenChange(false)
      props.onSuccess?.()
      userAvailability.refetch()
    },
    onError: () => {
      toast({ title: t('toast.failed-to-save-changes'), variant: 'error' })
    }
  })

  const handleClose = () =>
    isDirty ? setIsDirtyModalOpen(true) : props.onOpenChange(false)

  useExternalErrors(formErrors, form)

  return (
    <>
      <ModalForm
        id="availability-modal"
        form={form}
        onSubmit={data => {
          // TODO: user validation
          if (!userId) return
          setAvailability({
            ...data,
            semesterId: props.semester.id,
            userId
          })
        }}
        open={props.open}
        submitText={t('button.set')}
        title={
          props.title ??
          t('header.set-availability-for-semester', {
            SEMESTER: props.semester.name
          })
        }
        onCancel={handleClose}
        onClose={handleClose}
        onOpenChange={props.onOpenChange}
        size="md"
        loading={userAvailability.isLoading || isPending}
        contentClassName={styles.modal}
      >
        <div className={styles.container}>
          {props.userId ? null : (
            <div>
              <Label
                id="availability-for-user"
                label={t('label.user')}
                required
              />
              <AsyncSelect
                id="availability-for-user"
                value={user}
                onChange={setUser}
                placeholder={t('placeholder.select-user')}
                queryOptions={search => usersQueryOptions({ search })}
              />
            </div>
          )}
          {userId ? (
            daysOfWeek.map(day => (
              <DayAvailability
                form={form}
                key={day}
                day={day}
                appendOnSwitchOn={userAvailability.data?.[day].length === 0}
              />
            ))
          ) : (
            <span className={styles.help}>
              {t('help.select-user-to-set-availability')}
            </span>
          )}
        </div>
      </ModalForm>
      <DirtyModal
        isOpen={isDirtyModalOpen}
        onOpenChange={setIsDirtyModalOpen}
        onConfirm={() => {
          props.onOpenChange(false)
          setIsDirtyModalOpen(false)
        }}
      />
    </>
  )
}

const parseAvailabilitySlots = (
  availabilitySlots: AvailabilitySlot[]
): AvailabilitySlotForm[] =>
  availabilitySlots.map(({ endTime, isOptional, startTime }) => ({
    isOptional,
    time: {
      from: startTime,
      to: endTime
    }
  }))

type DayAvailabilityProps = {
  form: UseFormReturn<SetAvailabilityForm>
  day: DayOfWeek
  appendOnSwitchOn: boolean
}

const defaultSlotData = {
  time: {
    from: undefined,
    to: undefined
  },
  isOptional: false
}

const DayAvailability = (props: DayAvailabilityProps) => {
  const { t } = useTranslation('availabilityManagement')

  const { fields, replace, insert, remove } = useFieldArray({
    control: props.form.control,
    name: props.day
  })

  const handleChange = (value: boolean) => {
    if (value) {
      if (props.appendOnSwitchOn) replace([{ ...defaultSlotData }])
      else props.form.resetField(props.day)
    } else replace([])
  }

  const {
    fieldState: { error }
  } = useController({
    name: props.day,
    control: props.form.control
  })

  return (
    <div
      className={styles.dayAvailability}
      onChange={() => props.form.clearErrors(props.day)}
    >
      <Switch
        id={`enable-availability-for-${props.day}`}
        value={!!fields.length}
        onChange={handleChange}
        label={t(`label.week-days.${props.day}`)}
        className={styles.daySwitch}
      />
      {fields.length ? (
        <div className={styles.daySlots}>
          {fields.map((slot, index) => (
            <Slot
              form={props.form}
              index={index}
              onAdd={() => insert(index + 1, { ...defaultSlotData })}
              onRemove={() => remove(index)}
              key={slot.id}
              day={props.day}
              slot={slot}
              withDelete={fields.length > 1}
            />
          ))}
        </div>
      ) : (
        <Unavailable />
      )}
      <span className={styles.errorText}>{error?.message}</span>
    </div>
  )
}

type SlotProps = {
  form: UseFormReturn<SetAvailabilityForm>
  index: number
  day: DayOfWeek
  slot: AvailabilitySlotForm
  onAdd: () => void
  onRemove: () => void
  withDelete: boolean
}

const Slot = (props: SlotProps) => {
  const { t } = useTranslation('availabilityManagement')
  return (
    <div className={styles.slot}>
      <FormField
        id={`${props.day}-${props.index}-optional`}
        control={props.form.control}
        name={`${props.day}.${props.index}.isOptional`}
        render={({ inputProps }) => (
          <Checkbox {...inputProps} label={t('label.optional')} />
        )}
      />
      <div className={styles.editSlot}>
        <FormField
          id={`${props.day}.${props.index}.time`}
          control={props.form.control}
          name={`${props.day}.${props.index}.time`}
          className={styles.timeRangePicker}
          render={({ inputProps }) => <TimeRangePicker {...inputProps} />}
        />
        <div className={styles.slotButtons}>
          <ButtonIcon
            ariaLabel={t('button.add-slot')}
            variant="secondary"
            size="medium"
            onClick={props.onAdd}
            className={clsx(!props.withDelete && styles.withoutDelete)}
          >
            <PlusIcon />
          </ButtonIcon>
          {props.withDelete ? (
            <ButtonIcon
              ariaLabel={t('button.remove-slot')}
              size="medium"
              variant="tertiary"
              onClick={props.onRemove}
            >
              <DeleteIcon />
            </ButtonIcon>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const Unavailable = () => {
  const { t } = useTranslation('availabilityManagement')
  return (
    <div className={styles.unavailable}>
      <MoonIcon />
      {t('label.unavailable')}
    </div>
  )
}

export default AvailabilityModal
