import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString()
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>) =>
  response.map(item => ({
    value: item.id,
    label: item.name
  }))

const useAcademicLevels = () =>
  useQuery({
    queryKey: ['panelAcademicLevelsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelAcademicLevelsList({
        fetchKeys: {
          id: true,
          name: true
        }
      })

      const parsedAcademicLevels = parseResponse(
        responseSchema.parse(response.results)
      )

      return {
        options: parsedAcademicLevels
      }
    }
  })

export default useAcademicLevels
