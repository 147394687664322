import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'

import styles from './ClassEditView.module.scss'
import useClass from '../queries/useClass'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/edit/$classId'
)

const ClassEditView = () => {
  const { t } = useTranslation(['classes', 'common'])

  const { classId } = routeApi.useParams()

  const { data: classData, isLoading } = useClass(classId)

  return (
    <BasicLayout
      moduleName={t('common:header.academics')}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{classData?.name}</h1>
        </div>
      }
      subheader={t('header.edit-class')}
    >
      <Loading spinning={isLoading}>TODO</Loading>
    </BasicLayout>
  )
}

export default ClassEditView
