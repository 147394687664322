import { createFileRoute, redirect } from '@tanstack/react-router'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import i18n from '@/i18n'
import {
  availabilityManagementFiltersSchema,
  AvailabilityManagementView
} from '@/modules/availabilityManagement'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/availability-management/')({
  component: AvailabilityManagementView,
  validateSearch: availabilityManagementFiltersSchema,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ deps: { search }, context }) => {
    const semesters =
      await context.queryClient.ensureQueryData(semesterQueryOptions)
    if (!search.semester) {
      throw redirect({
        replace: true,
        to: '/availability-management',
        search: {
          semester: semesters.currentSemester?.id,
          page: 1,
          pageSize: DEFAULT_PAGE_SIZE,
          day: 'monday'
        }
      })
    }
  },
  meta: () => [
    { title: i18n.t('navigation.availability-management', { ns: 'common' }) }
  ]
})
