import { useTranslation } from 'react-i18next'

import BasicLayout from '@/components/common/BasicLayout/BasicLayout'

import styles from './ClassAddView.module.scss'

const ClassAddView = () => {
  const { t } = useTranslation(['classes', 'common'])

  return (
    <BasicLayout
      header={t('header.add-new-class')}
      moduleName={t('common:header.academics')}
    >
      <div className={styles.container}>
        <p className={styles.header}>{t('header.basic-information')}</p>
      </div>
      <div className={styles.container}>
        <p className={styles.header}>{t('header.students')}</p>
      </div>
    </BasicLayout>
  )
}

export default ClassAddView
