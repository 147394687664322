import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    academic_level: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    tutor: z
      .object({
        id: requiredString(),
        full_name: requiredString(),
        first_name: requiredString(),
        last_name: requiredString()
      })
      .nullable()
      .optional()
  })
)

export type Class = {
  id: string
  name: string
  academicLevel?: {
    id: string
    name: string
  }
  tutor?: {
    id: string
    fullName: string
  }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Class[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    academicLevel: item.academic_level || undefined,
    tutor: item.tutor
      ? {
          id: item.tutor?.id,
          fullName: item.tutor?.full_name
        }
      : undefined
  }))

// type Params = {

const useClassesOptions = () =>
  useQuery({
    queryKey: ['panelClassesList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesList({
        page_size: 'max',
        fetchKeys: {
          id: true,
          name: true,
          tutor: true,
          academic_level: true
        }
      })

      return parseResponse(responseSchema.parse(response.results))
    }
  })

export default useClassesOptions
