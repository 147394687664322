import type { UseNavigateResult } from '@tanstack/react-router'
import { isWithinInterval } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LinkInternalIcon from '@/assets/icons/link-internal.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import { type SelectOption } from '@/components/Select/Select'
import Table, { type Column, type TableAction } from '@/components/Table/Table'
import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import CoverSettingModal from '@/components/common/CoverSettingModal/CoverSettingModal'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useSemesters from '@/queries/useSemesters'
import { type CoveredOrCancelledLesson } from '@/types/lesson'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'
import { formatDateTime, formatTime } from '@/utils/format-date'
import { getFilterValue } from '@/utils/get-filter-value'
import { getValue } from '@/utils/get-value'

import styles from './CoveredAndCancelledLessonsList.module.scss'
import { TruncateColumn } from './TruncateColumn'
import type {
  LessonsFilters,
  LessonsFiltersKey
} from '../constants/lessons-filters'
import {
  classesQueryOptions,
  coursesQueryOptions,
  groupsQueryOptions,
  teachersQueryOptions
} from '../queries/coveredAndCancelledLessonFiltersOptions'
import useLessons from '../queries/useLessons'

type CoveredAndCancelledLessonsListProps = {
  id: string
  actions: TableAction<CoveredOrCancelledLesson>[]
  search: LessonsFilters
  onNavigate: UseNavigateResult<
    | '/covered-and-cancelled-lessons/cancelled'
    | '/covered-and-cancelled-lessons/covered'
  >
  coverSettingAction: {
    text: string
    icon: React.ReactNode
  }
  coverCancelButton: {
    text: string
    icon: React.ReactNode
  }
  additionalColumns?: Column<CoveredOrCancelledLesson>[]
}

export const CoveredAndCancelledLessonsList = (
  props: CoveredAndCancelledLessonsListProps
) => {
  const { t } = useTranslation('coveredCancelledLessons')

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    refetch: refetchLessonList
  } = useLessons({
    ...props.search,
    teacherIds: props.search.teacherIds?.map(getValue),
    classIds: props.search.classIds?.map(getValue),
    courseIds: props.search.courseIds?.map(getValue),
    groupIds: props.search.groupIds?.map(getValue)
  })

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const tableColumns: Column<CoveredOrCancelledLesson>[] = [
    ...(props.additionalColumns ? props.additionalColumns : []),
    {
      dataIndex: ['course', 'name'],
      key: 'course',
      title: t('header.course'),
      width: 160
    },
    {
      dataIndex: ['group', 'name'],
      key: 'group',
      title: t('header.group'),
      width: 160
    },
    {
      key: 'class',
      title: t('header.class'),
      width: 160,
      render: (lesson: CoveredOrCancelledLesson) => (
        <TruncateColumn
          list={lesson.studentClasses}
          renderElement={item => (
            <LinkText
              key={item.id}
              className={styles.truncateElement}
              to="/students-and-classes/classes/$classId/details"
              params={{
                classId: item.id
              }}
            >
              {item.name}
            </LinkText>
          )}
        />
      )
    },
    {
      key: 'date',
      width: 200,
      title: t('header.date'),
      render: (lesson: CoveredOrCancelledLesson) => (
        <span>
          {`${formatDateTime(lesson?.startDate)} - ${formatTime(lesson?.endDate)}`}
        </span>
      )
    }
  ]

  const handleChangePage = (value: number) => {
    props.onNavigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    props.onNavigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    props.onNavigate({
      search: { page: 1, pageSize: DEFAULT_PAGE_SIZE }
    })
  }

  const handleChangeFilter = (
    key: LessonsFiltersKey,
    value?: string | string[] | Date | boolean | SelectOption<string>[]
  ) => {
    props.onNavigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const isDateInsideSelectedSemester = (date: Date) => {
    const selectedSemester = semesters?.list.find(
      semester => semester.id === props.search.semesterId
    )

    if (!selectedSemester) return
    return isWithinInterval(date, {
      start: selectedSemester.startDate,
      end: selectedSemester.endDate
    })
  }

  const shouldDateBeDisabled = (date: Date) =>
    !isDateInsideSelectedSemester(date)

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: props.search.semesterId,
        placeholder: t('label.semester'),
        onChange: value => {
          props.onNavigate({
            search: {
              semesterId: value,
              page: 1,
              pageSize: DEFAULT_PAGE_SIZE
            }
          })
        }
      }
    },
    {
      label: t('label.teacher'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'teacher',
        multiple: true,
        queryOptions: teachersQueryOptions,
        value: props.search.teacherIds,
        placeholder: t('label.teacher'),
        onChange: value => handleChangeFilter('teacherIds', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'courseIds',
        multiple: true,
        queryOptions: coursesQueryOptions,
        value: props.search.courseIds,
        placeholder: t('label.course'),
        onChange: value => handleChangeFilter('courseIds', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'groupIds',
        multiple: true,
        queryOptions: groupsQueryOptions,
        value: props.search.groupIds,
        placeholder: t('label.group'),
        onChange: value => handleChangeFilter('groupIds', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'classIds',
        multiple: true,
        queryOptions: classesQueryOptions,
        value: props.search.classIds,
        placeholder: t('label.class'),
        onChange: value => handleChangeFilter('classIds', value)
      }
    },
    {
      label: t('label.date-range'),
      variant: 'date-range',
      size: 'wide',
      filterProps: {
        id: 'range',
        value: {
          from: props.search.dateAfter
            ? new Date(props.search.dateAfter)
            : undefined,
          to: props.search.dateBefore
            ? new Date(props.search.dateBefore)
            : undefined
        },
        disabledDate: shouldDateBeDisabled,
        onChange: value => {
          handleChangeFilter('dateAfter', value?.from)
          handleChangeFilter('dateBefore', value?.to)
        }
      }
    },
    {
      label: t('label.show-past-data'),
      variant: 'checkbox',
      size: 'flexible',
      filterProps: {
        id: 'show-past-data',
        value: props.search.showPastData,
        label: t('label.show-past-data'),
        onChange: value => handleChangeFilter('showPastData', value)
      }
    }
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters, ['semester'])

  const [currentLesson, setCurrentLesson] = useState<CoveredOrCancelledLesson>()
  const [isCoverSettingsModalOpen, setIsCoverSettingsModalOpen] =
    useState(false)

  const actions: TableAction<CoveredOrCancelledLesson>[] = [
    {
      icon: <LinkInternalIcon />,
      onClick: row => {
        if (!props.search.semesterId) return

        props.onNavigate({
          to: '/timetable/$courseId/$groupId/grades',
          params: {
            courseId: row.course.id,
            groupId: row.group.id
          },
          search: {
            semesterId: props.search.semesterId
          }
        })
      },
      text: t('button.go-to-gradebook')
    },
    {
      ...props.coverSettingAction,
      onClick: lesson => {
        setCurrentLesson(lesson)
        setIsCoverSettingsModalOpen(true)
      }
    },
    ...props.actions
  ]

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          <Button
            className={styles.addUserButton}
            asLink
            icon={props.coverCancelButton.icon}
            to="/covered-and-cancelled-lessons/add-cover-cancel"
          >
            {props.coverCancelButton.text}
          </Button>
        }
      />
      <Table
        id={props.id}
        actions={actions}
        isLoading={isLessonsLoading}
        data={lessons?.list || []}
        columns={tableColumns}
        pagination={{
          count: lessons?.count,
          pageSize: props.search.pageSize,
          page: props.search.page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <TextWithLabel
            label={t('label.comment')}
            variant="vertical"
            text={record.comment}
          />
        )}
      />

      {currentLesson ? (
        <CoverSettingModal
          open={isCoverSettingsModalOpen}
          onOpenChange={setIsCoverSettingsModalOpen}
          courseName={currentLesson.course.name}
          groupName={currentLesson.group.name}
          lesson={currentLesson}
          onCoverChange={() => {
            refetchLessonList()
            setCurrentLesson(undefined)
          }}
        />
      ) : null}
    </>
  )
}

export default CoveredAndCancelledLessonsList
