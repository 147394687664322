import clsx from 'clsx'
import { type UseFormReturn, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type ModalSize, BaseModal } from './Modal'
import styles from './ModalForm.module.scss'
import Button, { type ButtonVariant } from '../Button/Button'
import Loading from '../Loading'

type ModalFormProps<TData extends Record<string, unknown>> = {
  id: string
  title: string
  submitText: string
  withRemoveButton?: boolean
  open: boolean
  size?: ModalSize
  loading?: boolean
  contentClassName?: string
  boxClassName?: string
  trigger?: React.ReactNode
  children?: React.ReactNode
  description?: React.ReactNode
  form: UseFormReturn<TData>
  onOpenChange?: (isOpen: boolean) => void
  onClose?: () => void
  onCancel?: () => void
  onRemove?: () => void
  onSubmit: (payload: TData) => void
  submitButtonVariant?: ButtonVariant
}

const ModalForm = <TData extends Record<string, unknown>>(
  props: ModalFormProps<TData>
) => {
  const { t } = useTranslation(['common'])
  return (
    <BaseModal
      trigger={props.trigger}
      id={props.id}
      open={props.open}
      contentClassName={props.contentClassName}
      title={props.title}
      description={props.description}
      size={props.size}
      onClose={props.onClose}
      onOpenChange={props.onOpenChange}
    >
      <Loading spinning={props.loading}>
        <FormProvider {...props.form}>
          <form
            noValidate
            onSubmit={props.form.handleSubmit((payload: TData) =>
              props.onSubmit(payload)
            )}
            className={styles.form}
          >
            <div
              className={clsx(
                styles.box,
                props.description && styles.boxWithDescription,
                props.boxClassName
              )}
            >
              {props.children}
            </div>

            <div className={styles.footer}>
              <div className={styles.removeWrapper}>
                {props.withRemoveButton ? (
                  <Button
                    variant="danger"
                    className={styles.removeButton}
                    onClick={props.onRemove}
                  >
                    {t('button.remove')}
                  </Button>
                ) : null}
              </div>

              <div className={styles.buttonWrapper}>
                <Button
                  variant="tertiary"
                  className={styles.closeButton}
                  onClick={props.onCancel}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  className={styles.submitButton}
                  type="submit"
                  loading={props.loading}
                  variant={props.submitButtonVariant}
                >
                  {props.submitText}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Loading>
    </BaseModal>
  )
}

export default ModalForm
